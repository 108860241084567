@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@media (max-width: 768px) {
  .CartItemInfo {
    padding: 0;
  }
}
.CartItemInfo > .inner {
  display: grid;
  grid-template-columns: 80px 1fr;
}
.CartItemInfo .image-wrap {
  padding: 0 8px;
  max-height: 65px;
  overflow: hidden;
}
.CartItemInfo .image-wrap img {
  width: 65px;
  font-size: 12px;
}
.CartItemInfo .col3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.CartItemInfo .product-name {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #2375bb;
  word-wrap: break-word;
}
.CartItemInfo .id-numbers {
  font-size: 11px;
  color: #999;
}
@media (max-width: 768px) {
  .CartItemInfo .id-numbers {
    font-size: 9px;
  }
}
.CartItemInfo .spacer {
  padding: 0 5px;
}
.CartItemInfo .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 320px;
}
