.QuoteList .MobileTable .icon,
.QuoteList .MobileTable .notifier {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteList .MobileTable {
  display: none;
}
@media (max-width: 768px) {
  .QuoteList .MobileTable {
    display: block;
  }
}
.QuoteList .MobileTable .top {
  padding: 8px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
}
.QuoteList .MobileTable .bottom {
  padding: 8px;
}
.QuoteList .MobileTable .bottom .right {
  font-weight: normal;
}
.QuoteList .MobileTable .toggleButton {
  width: 40px;
  padding: 8px;
}
.QuoteList .MobileTable h2 {
  font-size: 20px;
  padding: 0;
  margin: 0;
}
.QuoteList .MobileTable hr {
  height: 2px;
  border: none;
  background-color: #ddd;
  width: 98%;
  margin: 0 auto;
}
.QuoteList .MobileTable span {
  display: block;
  line-height: 14px;
}
.QuoteList .MobileTable .infoItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  line-height: 32px;
  padding: 8px 4px;
}
.QuoteList .MobileTable .infoItem > span {
  color: #454545;
  font-size: 14px;
}
.QuoteList .paginationCont {
  padding: 32px 0;
}
.QuoteList tr.heading {
  border-left: 1px solid #ddd;
}
.QuoteList tr {
  align-items: center;
  display: flex;
  flex-flow: row;
}
.QuoteList td span :empty {
  background-color: #ddd;
  color: #ddd;
}
.QuoteList .created_on {
  width: 150px;
}
.QuoteList .created_on .Icon {
  margin-right: 8px;
}
.QuoteList .created_on span {
  min-width: 73px;
}
.QuoteList .quote_name {
  width: 145px;
}
.QuoteList .id {
  width: 160px;
}
.QuoteList .created_by {
  width: 130px;
}
.QuoteList .end_user {
  width: 150px;
}
.QuoteList .total_price {
  width: 120px;
}
.QuoteList .last_updated {
  width: 145px;
}
.QuoteList .exp_date {
  width: 120px;
}
.QuoteList .status {
  width: 120px;
  position: relative;
}
.QuoteList .status .Icon.statusIcon {
  margin-right: 8px;
}
.QuoteList .bellNotify {
  animation: ringBell 1s 2s ease-in-out normal;
  transform-origin: 50% 0px;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}
.QuoteList .bellNotify:hover {
  background-color: #fff;
}
@-moz-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@-o-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
