@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteSearch {
  width: 940px;
  padding: 16px 0 24px 0;
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 400px 80px;
  grid-gap: 16px;
}
@media (max-width: 768px) {
  .QuoteSearch {
    width: 100%;
    grid-template-columns: 1fr;
    padding: 30px 4px;
  }
}
.QuoteSearch.status .multiSearch,
.QuoteSearch.total_price .multiSearch {
  grid-gap: 16px;
}
.QuoteSearch.last_updated,
.QuoteSearch.exp_date {
  grid-template-columns: 140px 400px 80px;
}
.QuoteSearch.last_updated .multiSearch,
.QuoteSearch.exp_date .multiSearch {
  grid-template-columns: 1fr;
}
.QuoteSearch .multiSearch {
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-gap: 0;
}
@media (max-width: 768px) {
  .QuoteSearch .multiSearch {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
@media (min-width: 768px) {
  .QuoteSearch input.default {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}
.QuoteSearch input.default:focus {
  box-shadow: none;
}
.QuoteSearch .DatePicker .DayPickerInput {
  width: 188px;
}
.QuoteSearch .Button {
  height: 38px;
}
.QuoteSearch .totalPriceCont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
}
