@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SlidePanel {
  position: fixed;
  top: 56px;
  bottom: 0;
  width: 500px;
  right: -600px;
  background-color: #fff;
  transition: transform 300ms;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
}
.SlidePanel h2 {
  position: fixed;
  top: 32px;
  left: 16px;
  right: 16px;
  font-size: 24px;
  border-bottom: 1px solid #999;
}
.SlidePanel > .inner {
  position: fixed;
  top: 0px;
  bottom: 8px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  z-index: 1;
}
@media (min-width: 768px) {
  .SlidePanel {
    top: 0;
    box-shadow: 0 0px 60px rgba(0,0,0,0.6);
  }
  .SlidePanel h2,
  .SlidePanel > .inner {
    left: 32px;
    right: 32px;
  }
}
.SlidePanel.open {
  transform: translateX(-600px);
  z-index: 4;
}
.SlidePanel.open > .inner {
  display: block;
}
.SlidePanel.leftSide {
  left: -600px;
  right: auto;
}
.SlidePanel.leftSide.open {
  transform: translateX(600px);
}
@media (max-width: 768px) {
  .SlidePanel {
    width: 100vw;
    right: -105vw;
  }
  .SlidePanel h2 {
    top: 19px;
  }
  .SlidePanel.open {
    transform: translateX(-105vw);
  }
  .SlidePanel.leftSide {
    left: -100vw;
    right: auto;
  }
  .SlidePanel.leftSide.open {
    transform: translateX(100vw);
  }
  .SlidePanel > .inner {
    padding: 0px;
  }
}
.SlidePanel .close {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .SlidePanel .close {
    top: 16px;
    right: 16px;
  }
}
.SlidePanel .close i {
  font-size: 30px;
}
.SlidePanel .close:hover {
  background-color: #eee;
}
