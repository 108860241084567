@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.AddressSearchPanel {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 32px;
  background-color: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .AddressSearchPanel {
    top: 70px;
    left: 16px;
    right: 16px;
    padding: 0;
  }
}
.AddressSearchPanel h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
  left: 32px;
  right: 32px;
}
@media (max-width: 768px) {
  .AddressSearchPanel h2 {
    left: 16px;
    right: 16px;
  }
}
.AddressSearchPanel .addressSearch {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 8px;
  padding: 16px 0;
}
.AddressSearchPanel .fixedBottom {
  position: fixed;
  padding: 8px 32px 8px 32px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.AddressList {
  padding: 0;
  margin-bottom: 32px;
}
.AddressList .address {
  border: 3px solid #fff;
  box-shadow: 0 0 8px #999;
  border-radius: 4px;
  margin: 0 4px;
  padding: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}
.AddressList .address:hover {
  background-color: #e9f2f9;
  border-color: #e9f2f9;
}
.AddressList .address.selected {
  border: 3px solid #2375bb;
}
.AddressList .address p {
  text-transform: uppercase;
  padding: 0;
}
.AddressList .address .name {
  color: #2375bb;
  font-weight: bold;
}
