.QuickOrderPanel .QuickEntry .lineNumber,
.QuickOrderPanel .QuickEntry .times {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuickOrderPanel {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 32px;
  background-color: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .QuickOrderPanel {
    top: 70px;
    left: 16px;
    right: 16px;
    padding: 0;
  }
}
.QuickOrderPanel h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
  left: 32px;
  right: 32px;
}
@media (max-width: 768px) {
  .QuickOrderPanel h2 {
    left: 16px;
    right: 16px;
  }
}
.QuickOrderPanel .info {
  padding: 16px 0;
}
@media (max-width: 768px) {
  .QuickOrderPanel .info {
    padding: 8px 0;
  }
}
.QuickOrderPanel .tab-titles li {
  width: 50%;
}
.QuickOrderPanel .QuickEntry {
  width: 100%;
  padding: 24px 0 0;
}
.QuickOrderPanel .QuickEntry .entryLine {
  padding: 8px 0;
  display: grid;
  grid-template-columns: 40px 276px 40px 80px;
}
@media (max-width: 768px) {
  .QuickOrderPanel .QuickEntry .entryLine {
    grid-template-columns: 40px 1fr 40px 80px;
  }
}
.QuickOrderPanel .QuickEntry .lineNumber,
.QuickOrderPanel .QuickEntry .times {
  font-size: 14px;
}
.QuickOrderPanel .QuickEntry .lineNumber {
  justify-content: flex-start;
}
.QuickOrderPanel .addNewRow {
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
}
.QuickOrderPanel .addNewRow a {
  color: #2375bb;
  text-decoration: none;
}
.QuickOrderPanel .addNewRow a:hover {
  text-decoration: underline;
}
.QuickOrderPanel .buttons {
  display: flex;
  justify-content: flex-end;
}
.QuickOrderPanel .buttons .Button {
  margin-left: 8px;
}
