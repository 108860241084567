@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.TextInput label {
  color: #333;
  display: block;
  font: 0.875rem "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin-bottom: 7px;
}
.TextInput .input {
  color: #333;
  background: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  line-height: 38px;
  padding: 0 6px;
  width: 100%;
  font-family: "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.TextInput.short .input {
  line-height: 26px;
}
.TextInput .input:disabled,
.TextInput .input[disabled="disabled"] {
  background: #ddd;
}
.TextInput .input:focus,
.TextInput .input.error:focus,
.TextInput .input.focus {
  -webkit-box-shadow: 0 0 8px #2375bb;
  outline: none;
}
.TextInput .input:focus -moz-box-shadow 0 0 8px $cBlue,
.TextInput .input.error:focus -moz-box-shadow 0 0 8px $cBlue,
.TextInput .input.focus -moz-box-shadow 0 0 8px $cBlue {
  box-shadow: 0 0 8px #2375bb;
}
.TextInput ::-webkit-input-placeholder {
  color: #999;
}
.TextInput :-ms-input-placeholder {
  color: #999;
}
.TextInput ::-ms-input-placeholder {
  color: #999;
}
.TextInput ::placeholder {
  color: #999;
}
.TextInput .input.error {
  background: #fff;
  border: 1px solid #c33d39;
  -webkit-box-shadow: 0 0 8px #c33d39;
  padding-right: 40px;
}
.TextInput .input.error -moz-box-shadow 0 0 8px $cRed {
  box-shadow: 0 0 8px #c33d39;
}
.TextInput .error-description {
  color: #c33d39;
  font-size: 0.875rem;
  margin: 7px 0 0;
}
.TextInput .noRightSide {
  position: relative;
  border-right: 1px solid #ddd;
  margin-right: -2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.TextInput .noLeftSide {
  position: relative;
  border-left: 1px solid #ddd;
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
