@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.MobileTable {
  padding: 0 4px;
}
.MobileTableItem {
  box-shadow: 0 0 4px #999;
  border-radius: 0;
  margin: 8px;
  margin-bottom: 12px;
}
.MobileTableItem .CartItemInfo {
  padding: 16px 8px 0 8px;
}
.MobileTableItem .infoItem {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.MobileTableItem .infoItem .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MobileTableItem .infoItem .right .currency {
  font-size: 20px;
}
.MobileTableItem .selectWarehouse {
  display: flex;
  flex-flow: row;
}
.MobileTableItem .infoPanel {
  padding-left: 8px;
  font-size: 12px;
  display: grid;
  grid-template-columns: 40px 1fr 60px;
  height: 40px;
  align-items: center;
  border-bottom: 0.5px solid #ddd;
  cursor: pointer;
}
.MobileTableItem .infoPanel > .Icon i {
  font-size: 14px;
}
.MobileTableItem .infoPanel > span {
  display: flex;
  align-items: center;
  flex-flow: row;
  color: #2375bb;
  height: 40px;
}
.MobileTableItem .infoPanel.in-stock {
  border-top: 0.5px solid #ddd;
}
.MobileTableItem .infoPanel.in-stock .InStock {
  padding-bottom: 0;
  display: flex;
  margin-left: 16px;
  font-size: 12px;
  height: 40px;
  align-items: center;
}
