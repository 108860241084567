@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.ProductDetail .col2 {
  grid-gap: 16px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .ProductDetail .Tabs .tab {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .ProductDetail .Tabs {
    grid-template-columns: 1fr;
  }
  .ProductDetail .Tabs .left,
  .ProductDetail .Tabs .right {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.ProductDetail .productListingHeading {
  text-align: center;
}
@media (max-width: 768px) {
  .ProductDetail .Carousel {
    width: 98vw;
  }
  .ProductDetail .ProductListing {
    max-width: 100%;
  }
}
.Bundles,
.Accessories {
  width: 100%;
}
.Bundles .Carousel,
.Accessories .Carousel {
  width: 600px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .Bundles .Carousel,
  .Accessories .Carousel {
    width: 98vw;
  }
}
.Bundles .Carousel .slide-slide,
.Accessories .Carousel .slide-slide {
  width: 180px;
}
.Bundles .Carousel .slick-next,
.Accessories .Carousel .slick-next {
  right: -16px;
}
.Bundles .Carousel .slick-prev,
.Accessories .Carousel .slick-prev {
  left: -16px;
}
@media (max-width: 768px) {
  .Bundles,
  .Accessories {
    width: 98vw;
  }
}
.ProductDescription h3 {
  color: #2375bb;
}
.ProductDescription p.bold {
  font-weight: bold;
}
@media (max-width: 768px) {
  .ProductDescription {
    padding: 0 2px;
  }
}
.ProductDescription .Accordion {
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 768px) {
  .ProductDescription .Accordion {
    padding: 12px 8px;
  }
}
.Description {
  padding: 16px 8px;
}
.Description p {
  font-size: 12px;
  padding: 16px 0;
}
.Description p.bold {
  padding: 0;
}
.Description ul {
  margin: 0 0 16px 0;
}
.Description li {
  font-size: 12px;
}
.PricingInfo,
.Warranty {
  padding: 8px;
}
.PricingInfo p,
.Warranty p {
  font-size: 12px;
  padding-bottom: 16px;
}
.TechSpecs {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 40px;
}
@media (max-width: 768px) {
  .TechSpecs {
    grid-template-columns: 1fr;
  }
}
.TechSpecs h5,
.TechSpecs p {
  font-size: 12px;
}
.TechSpecs a {
  color: #2375bb;
}
.TechSpecs .spec {
  border-top: 1px solid #ddd;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font-size: 12px;
}
.TechSpecs .spec:last-of-type {
  border-bottom: 1px solid #ddd;
}
.TechSpecs .spec > .left,
.TechSpecs .spec > .right {
  padding: 10px;
}
.TechSpecs .spec > .left {
  background-color: #eee;
}
.TechSpecs .spec ul {
  padding-left: 10px;
}
