.DatePicker .mobileOnly {
  display: none;
}
@media (max-width: 768px) {
  .DatePicker .mobileOnly {
    display: flex;
  }
}
.DatePicker .desktopOnly {
  display: flex;
}
@media (max-width: 768px) {
  .DatePicker .desktopOnly {
    display: none;
  }
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.DatePicker input[type="date"],
.DatePicker .DayPickerInput input {
  color: #333;
  background: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  display: block;
  font-size: 14px;
  line-height: 36px;
  padding: 0 6px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 768px) {
  .DatePicker input[type="date"],
  .DatePicker .DayPickerInput input {
    margin-bottom: 16px;
  }
}
.DatePicker {
  font-size: 14px;
}
.DatePicker .DayPickerInput-Overlay {
  top: 0px;
}
.DatePicker .prevMonth {
  position: absolute;
  top: 16px;
  left: 16px;
}
.DatePicker .nextMonth {
  position: absolute;
  top: 16px;
  right: 16px;
}
.DatePicker .DayPicker {
  z-index: 2;
}
.DatePicker .DayPicker-Caption {
  text-align: center;
  color: #2375bb;
  text-transform: uppercase;
  margin-top: -16px;
  font-size: 14px;
}
.DatePicker .DayPicker-Months {
  flex-flow: row;
}
.DatePicker .DayPicker-Month {
  margin: 32px 8px -16px 8px;
  border-collapse: inherit;
  border-spacing: 1px;
  font-size: 14px;
}
.DatePicker .DayPicker-Weekday {
  background-color: #ddd;
  font-weight: bold;
  color: #000;
  margin: 0 1px;
}
.DatePicker .Icon {
  cursor: pointer;
}
.DatePicker .DayPicker-Day {
  border-radius: 0;
}
.DatePicker .DayPicker-Day--today {
  color: #e1500b;
}
.DatePicker .to {
  display: flex;
  align-items: center;
  margin: 0 8px;
}
.DatePicker .DayPickerInput {
  position: relative;
  z-index: 2;
  width: 257px;
}
@media (max-width: 768px) {
  .DatePicker .DayPickerInput {
    display: none;
  }
}
.DatePicker .DayPickerInput:before {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f073";
  color: #999;
  font-size: 14px;
}
.DatePicker .DayPickerInput input:disabled,
.DatePicker .DayPickerInput input[disabled="disabled"] {
  background: #ddd;
}
.DatePicker .DayPickerInput input:focus,
.DatePicker .DayPickerInput input.error:focus,
.DatePicker .DayPickerInput input.focus {
  -webkit-box-shadow: 0 0 8px #2375bb;
  outline: none;
}
.DatePicker .DayPickerInput input:focus -moz-box-shadow 0 0 8px $cBlue,
.DatePicker .DayPickerInput input.error:focus -moz-box-shadow 0 0 8px $cBlue,
.DatePicker .DayPickerInput input.focus -moz-box-shadow 0 0 8px $cBlue {
  box-shadow: 0 0 8px #2375bb;
}
.DatePicker .DayPickerInput ::-webkit-input-placeholder {
  color: #999;
}
.DatePicker .DayPickerInput :-ms-input-placeholder {
  color: #999;
}
.DatePicker .DayPickerInput ::-ms-input-placeholder {
  color: #999;
}
.DatePicker .DayPickerInput ::placeholder {
  color: #999;
}
.DatePicker .DayPickerInput input.error {
  background: #fff;
  border: 1px solid #c33d39;
  -webkit-box-shadow: 0 0 8px #c33d39;
  padding-right: 40px;
}
.DatePicker .DayPickerInput input.error -moz-box-shadow 0 0 8px $cRed {
  box-shadow: 0 0 8px #c33d39;
}
.DatePicker .DayPickerInput .error-description {
  color: #c33d39;
  font-size: 14px;
  margin: 7px 0 0;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  color: #2375bb;
  background-color: rgba(35,117,183,0.1);
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):before {
  display: block;
  background-color: #2375bb;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.InputFromTo .DayPicker-Day .DayPickerInput-Overlay {
  width: 550px;
}
.InputFromTo .InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}
