@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteInfo {
  background-color: #eee;
  padding: 24px 16px;
  margin-bottom: 16px;
}
.QuoteInfo h4 {
  padding-bottom: 12px;
}
.QuoteInfo .inputCont {
  padding: 12px 0;
  font-size: 14px;
}
.QuoteInfo .inputCont > label {
  font-weight: bold;
  margin-bottom: 6px;
}
.QuoteInfo .inputCont .DatePicker .DayPickerInput {
  width: 100%;
}
.QuoteInfo .inputCont .right {
  display: flex;
  justify-content: flex-end;
}
.QuoteInfo label {
  color: #333;
  display: block;
  font-size: 14px;
}
.QuoteInfo .profitValue {
  padding: 6px 0;
}
.QuoteInfo .security .left {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .QuoteInfo {
    padding: 24px 16px;
  }
  .QuoteInfo .col2 {
    grid-template-columns: 1fr 1fr;
  }
}
