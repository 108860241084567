@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@media (max-width: 768px) {
  .CartTotal {
    margin: 8px;
  }
}
.CartTotal:after {
  clear: both;
  content: "";
  display: block;
}
.CartTotal > .inner {
  float: right;
  border: 1px solid #ddd;
  padding: 16px 32px;
  width: 460px;
  margin: 16px 0 0 16px;
}
@media (max-width: 768px) {
  .CartTotal > .inner {
    float: none;
    width: 100%;
    margin: 0;
    padding: 16px;
    box-shadow: 0 0 4px #999;
  }
}
.CartTotal .couponCode {
  padding-left: 8px;
  padding-right: 8px;
  background-color: #ddd;
}
.CartTotal .couponCode .codeText .Icon {
  float: left;
  margin-right: 8px;
  padding: 0 4px;
}
.CartTotal .couponCode .codeText .Icon:hover {
  cursor: pointer;
  background-color: #999;
}
.CartTotal h3.summary {
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.CartTotal .col2 {
  grid-template-columns: 70% 30%;
  padding: 8px 0;
}
.CartTotal .col2 .right {
  display: flex;
  justify-content: flex-end;
}
.CartTotal .estimateFreight {
  padding: 8px 0;
}
.CartTotal .estimateFreight a {
  color: #2375bb;
  text-decoration: none;
}
.CartTotal .total {
  font-size: 24px;
  border-top: 1px solid #ddd;
  padding: 16px 0;
}
.CartTotal .buttons {
  padding: 8px 0;
}
@media (max-width: 768px) {
  .CartTotal .buttons {
    display: none;
  }
}
.CartTotal .notice {
  color: #0b7630;
  letter-spacing: 1px;
}
.CartTotal .buttons > .Button {
  margin: 16px 0;
}
.CartTotal .continueShopping {
  text-align: center;
  margin-top: 16px;
}
.CartTotal .continueShopping a {
  color: #454545;
  text-decoration: none;
}
.CartTotal .continueShopping a:hover {
  text-decoration: underline;
}
