@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Tabs {
  box-sizing: border-box;
}
.Tabs .tab-titles {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  background-color: #eee;
}
.Tabs .tab-titles .tab {
  min-width: 200px;
  user-select: none;
  line-height: 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-weight: bold;
  border-bottom: 1.5px solid #ddd;
  background-color: #eee;
}
@media (max-width: 768px) {
  .Tabs .tab-titles .tab {
    min-width: 0;
    font-size: 12px;
  }
}
.Tabs .tab-titles .tab:before {
  content: "";
  height: 4px;
  border-right: 1.5px solid #eee;
  background-color: #eee;
}
.Tabs .tab-titles .tab.active {
  border-bottom: 1.5px solid #fff;
}
.Tabs .tab-titles .tab.active:before {
  display: block;
  height: 4px;
  border-right: 1.5px solid #2375bb;
  border-left: 1.5px solid #2375bb;
  background-color: #2375bb;
  content: "";
  width: 100%;
}
.Tabs .tab-titles a {
  color: #999;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: block;
}
.Tabs .tab-titles .active {
  border-left: 1.5px solid #ddd;
  border-right: 1.5px solid #ddd;
  background-color: #fff;
}
.Tabs .tab-titles .active a {
  color: #2375bb;
}
.Tabs .tabs-container .tab-panel {
  background-color: #fff;
  display: none;
}
.Tabs .tabs-container .tab-panel.active {
  display: flex;
}
.Tabs .tabs-container > .default {
  min-height: 300px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
