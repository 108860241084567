.CartItem .in-stock,
.CartItem .middle,
.CartItem .middle .deleteButton,
.CartItem .price {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@media (max-width: 768px) {
  .CartItem {
    padding: 0;
  }
}
.CartItem > .inner {
  padding: 16px 0 16px 0;
  display: grid;
  grid-template-columns: 80px 1fr;
  border-bottom: 1px solid #ddd;
}
.CartItem .image-wrap {
  padding: 0 8px;
}
.CartItem .image-wrap img {
  width: 65px;
}
.CartItem .col3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.CartItem .in-stock {
  justify-content: flex-start;
  color: #0b7630;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
}
.CartItem .in-stock:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 900;
  content: "\f078";
  margin-left: 4px;
}
.CartItem .in-stock.out {
  color: #c33d39;
}
@media (max-width: 768px) {
  .CartItem .in-stock {
    font-size: 12px;
  }
}
.CartItem .product-name {
  margin-top: -4px;
  margin-bottom: 8px;
  line-height: 16px;
  color: #2375bb;
}
.CartItem .id-numbers {
  font-size: 11px;
  color: #454545;
  padding: 0 0 8px 0;
}
@media (max-width: 768px) {
  .CartItem .id-numbers {
    font-size: 9px;
  }
}
.CartItem .spacer {
  padding: 0 10px;
}
.CartItem .middle .NumberInput {
  margin-right: 8px;
}
.CartItem .middle .deleteButton {
  cursor: pointer;
  border-radius: 100%;
  width: 80px;
  height: 40px;
  font-size: 18px;
}
.CartItem .middle .deleteButton:hover {
  background-color: #eee;
}
.CartItem .price {
  justify-content: flex-end;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
}
@media (max-width: 768px) {
  .CartItem .price .currency {
    Cfont-size: 18px;
  }
}
