@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.NotificationPanel {
  position: fixed;
  top: 68px;
  left: 32px;
  right: 32px;
  bottom: 8px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .NotificationPanel {
    margin-top: 60px;
    left: 16px;
    right: 16px;
  }
}
.NotificationPanel h2 {
  font-size: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #999;
}
@media (max-width: 768px) {
  .NotificationPanel h2 {
    left: 16px;
    right: 16px;
  }
}
.NotificationPanel .Icon {
  margin-right: 0px;
  margin-top: -4px;
  margin-left: -10px;
}
.NotificationPanel > .inner {
  padding: 0;
  position: relative;
}
.NotificationPanel .heading h2 {
  display: flex;
}
.NotificationPanel .bellNotify {
  animation: ringBell 1s 2s ease-in-out normal;
  transform-origin: 50% 0px;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}
.NotificationPanel .bellNotify:hover {
  background-color: #fff;
}
@-moz-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@-o-keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes ringBell {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(-20deg);
  }
  14% {
    transform: rotate(18deg);
  }
  21% {
    transform: rotate(-16deg);
  }
  28% {
    transform: rotate(14deg);
  }
  35% {
    transform: rotate(-12deg);
  }
  42% {
    transform: rotate(10deg);
  }
  49% {
    transform: rotate(-8deg);
  }
  56% {
    transform: rotate(6deg);
  }
  63% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  77% {
    transform: rotate(-1deg);
  }
  84% {
    transform: rotate(1deg);
  }
  91% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
