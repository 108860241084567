@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SelectMenu.disabled .Select {
  background-color: #ddd;
}
.SelectMenu .Select {
  background-color: #fff;
  border-radius: 5px;
}
.SelectMenu .Select.error {
  box-shadow: 0 0 10px #c33d39;
}
.SelectMenu .Select.focus {
  box-shadow: 0 0 10px #2375bb;
}
.SelectMenu .Select.noRightSide .Select__control {
  position: relative;
  border-right: 1px solid #ddd;
  margin-right: -2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.SelectMenu .Select.noLeftSide .Select__control {
  position: relative;
  border-left: 1px solid #ddd;
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.SelectMenu .Select__control {
  align-items: center;
  background-color: transparent;
  border-color: #999;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  min-width: 62px;
  height: 40px;
}
.SelectMenu .Select__control:hover {
  box-shadow: none;
}
.SelectMenu .Select__control:focus {
  box-shadow: 0 0 10px #2375bb;
}
.SelectMenu .Select__control--is-focused {
  box-shadow: none;
}
.SelectMenu .Select__indicator {
  box-sizing: border-box;
  color: #2375bb;
  display: flex;
  padding: 8px;
  transition: color 150ms;
}
.SelectMenu .Select__indicator:hover {
  color: #999;
}
.SelectMenu .Select__indicator-separator {
  background-color: #fff;
}
.SelectMenu .Select__option {
  cursor: pointer;
}
.SelectMenu .Select__option--is-selected {
  background: rgba(35,117,183,0.1);
  color: #000;
}
.SelectMenu .Select__option--is-focused {
  color: #fff;
  background-color: #2375bb;
}
.SelectMenu .Select__menu {
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 11px rgba(0,0,0,0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 99;
}
.SelectMenu .Select__menu-list {
  background-color: #fff;
}
.SelectMenu select {
  height: 36px;
  width: 100%;
  display: block;
}
.SelectMenu label {
  color: #454545;
  display: block;
  margin-bottom: 7px;
  font: 0.875rem HelveticaNeue-Bold, "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.SelectMenu .error-description {
  color: #c33d39;
  font-size: 14px;
  margin: 5px 0 0 0;
}
