@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.MobileSwitchCarts h2 {
  font-size: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #999;
}
.MobileSwitchCarts > .inner {
  padding: 40px 0 0 0;
  position: relative;
}
.MobileSwitchCarts .SwitchCartItem {
  border: 2px solid #fff;
  width: 100%;
  padding: 16px;
  border-radius: 2px;
  box-shadow: 0 0 2px #999;
  transition: box-shadow 300ms;
  margin-bottom: 16px;
  cursor: pointer;
}
.MobileSwitchCarts .SwitchCartItem > .topLine {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.MobileSwitchCarts .SwitchCartItem > .midLine {
  padding: 8px 0;
}
.MobileSwitchCarts .SwitchCartItem .name {
  text-align: left;
  color: #2375bb;
  font-size: 14px;
  font-weight: bold;
}
.MobileSwitchCarts .SwitchCartItem .cartPrice {
  font-size: 14px;
  text-align: right;
  font-weight: bold;
}
.MobileSwitchCarts .SwitchCartItem .endUser {
  color: #999;
  display: flex;
  font-size: 12px;
}
.MobileSwitchCarts .SwitchCartItem .endUser .Icon {
  margin-right: 8px;
}
.MobileSwitchCarts .SwitchCartItem.active {
  border-color: #2375bb;
}
.MobileSwitchCarts .SwitchCartItem:hover {
  box-shadow: 2px 2px 6px #ddd;
}
.MobileSwitchCarts .Thumbs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.MobileSwitchCarts .Thumbs .image {
  border: 1px solid #ddd;
  height: 48px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
.MobileSwitchCarts .Thumbs .image img {
  height: 40px;
}
.MobileSwitchCarts .Thumbs .plusMore {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 8px;
}
.MobileSwitchCarts .Thumbs .plusMore span {
  font-size: 12px;
}
.MobileSwitchCarts .MobileStickyBottom {
  position: fixed;
  background-color: #fff;
  padding: 8px 32px;
  bottom: 0;
  left: 0;
  right: 0;
}
