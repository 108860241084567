@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SelectWarehousePanel {
  position: fixed;
  top: 80px;
  left: 32px;
  right: 32px;
  padding: 8px 0;
  background-color: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .SelectWarehousePanel {
    top: 50px;
    left: 16px;
    right: 16px;
  }
}
.SelectWarehousePanel h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
  left: 32px;
  right: 32px;
}
@media (max-width: 768px) {
  .SelectWarehousePanel h2 {
    left: 16px;
    right: 16px;
  }
}
.SelectWarehousePanel p {
  padding: 16px 0;
}
.SelectWarehousePanel .buttons {
  display: flex;
  justify-content: flex-end;
}
.SelectWarehousePanel .buttons .Button {
  margin-left: 8px;
}
.SelectWarehousePanel .Table td,
.SelectWarehousePanel .Table th {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}
.SelectWarehousePanel .Table .warehouse {
  width: 40%;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .SelectWarehousePanel .Table {
    display: table;
  }
}
