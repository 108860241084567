@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteLines {
  padding: 32px 0;
}
.QuoteLines > label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
}
@media (max-width: 768px) {
  .QuoteLines {
    width: 100%;
  }
  .QuoteLines .SearchElement,
  .QuoteLines > label {
    padding: 0 4px;
  }
}
.QuoteLines .Table {
  margin-top: 24px;
}
.QuoteLines .Table tr {
  display: flex;
  flex-flow: row;
}
.QuoteLines .Table td {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.QuoteLines .Table .check_col {
  display: none;
}
.QuoteLines .Table .item_desc {
  justify-content: flex-start;
  width: 50%;
}
.QuoteLines .Table .qty {
  width: 12%;
}
.QuoteLines .Table .user_price {
  width: 12%;
}
.QuoteLines .Table .markup {
  width: 18%;
}
.QuoteLines .Table .line_price {
  width: 18%;
}
.QuoteLines .MobileTable {
  margin-top: 16px;
}
.QuoteLines .MobileTable .infoItem {
  display: grid;
  grid-template-columns: 65% 35%;
  width: 100%;
  line-height: 32px;
  padding: 8px 4px;
}
@media (max-width: 768px) {
  .QuoteLines .MobileTable .infoItem .left,
  .QuoteLines .MobileTable .infoItem .right {
    padding: 0;
  }
}
.QuoteLines .MobileTable .infoItem > span {
  color: #454545;
  font-size: 14px;
}
.QuoteLines .notes {
  padding-top: 16px;
}
@media (max-width: 768px) {
  .QuoteLines .notes {
    padding: 16px 4px 0 4px;
  }
}
