@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@media (min-width: 768px) {
  .Header {
    display: block;
  }
  .MobileHeader {
    display: none;
  }
}
.Header {
  box-sizing: border-box;
  z-index: 4;
}
.Header .topLine {
  padding: 10px 40px;
  background-color: #eee;
  font-size: 14px;
}
.Header .topLine .subNav {
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 200px;
}
.Header .topLine .subNavLeft {
  display: flex;
  justify-content: flex-start;
}
.Header .topLine .subNavLeft a {
  margin-right: 40px;
}
.Header .topLine .subNavRight {
  display: flex;
  justify-content: flex-end;
}
.Header .topLine .region {
  text-decoration: underline;
  font-weight: 200;
}
.Header .topLine a {
  color: #999;
}
.Header .midLine {
  padding: 24px 40px;
  background-color: #2375bb;
}
.Header .midLine .subNav {
  display: grid;
  grid-template-columns: 192px 1fr;
  grid-gap: 16px;
  max-width: 1280px;
  margin: 0 auto;
}
.Header .midLine .SearchElement {
  max-width: 720px;
}
.Header .midLine .logo {
  color: #fff;
  width: 192px;
}
.Header .midLine .logo img {
  width: inherit;
}
.Header .botLine {
  padding: 0 40px 24px 40px;
  background-color: #2375bb;
}
.Header .botLine .subNav {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-gap: 16px;
  max-width: 1280px;
  margin: 0 auto;
}
.Header .botLine .subNavLeft {
  display: flex;
  justify-content: space-between;
}
.Header .botLine .subNavRight {
  display: flex;
  justify-content: space-between;
}
.Header .botLine a {
  color: #fff;
}
.Header .botLine .Icon {
  cursor: pointer;
}
.Header .navLink {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}
.Header .navIcon {
  position: relative;
}
.Header .navIcon .Icon {
  position: relative;
  z-index: 1;
}
