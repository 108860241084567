.Table tr.heading .col,
.Table tr.row:hover .rowPanel,
.Table tr.row:hover .rowPanel .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Table {
  border-spacing: 0;
  table-layout: fixed;
  display: table;
  position: relative;
  width: 100%;
}
.Table .col {
  min-height: 60px;
  font-size: 14px;
}
.Table .currency {
  font-size: 14px;
}
.Table .col.sorted {
  cursor: pointer;
  color: #2375bb;
}
.Table .sort {
  display: block;
}
.Table tr {
  display: flex;
  flex-flow: row;
}
.Table td {
  padding: 0;
}
.Table tr.heading {
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-size: 14px;
  user-select: none;
  box-shadow: none;
  width: 100%;
}
.Table tr.heading th {
  display: flex;
  align-items: center;
}
.Table tr.heading th.check_col {
  min-height: 61px;
}
.Table tr.heading:hover {
  background-color: #fff;
  box-shadow: none;
}
.Table tr.heading .HeadingPanel {
  position: absolute;
  top: 1px;
  left: 46px;
  width: 1232px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-flow: row;
  height: 60px;
  opacity: 0;
  z-index: -1;
}
.Table tr.heading .HeadingPanel.shown {
  opacity: 1;
  z-index: 1;
}
.Table tr.heading .HeadingPanel span.numRows {
  font-weight: normal;
}
.Table tr.heading .headingActions {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  width: 220px;
  margin-right: 20px;
  position: relative;
  height: 100%;
  align-items: center;
}
.Table tr.heading .headingActions .Icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 100%;
}
.Table tr.heading .headingActions .Icon i {
  color: #454545;
}
.Table tr.heading .headingActions .Icon:hover {
  background-color: rgba(35,117,183,0.1);
}
.Table tr.heading .headingActions .Icon:hover i {
  color: #005394;
}
.Table tr.heading th {
  padding: 0;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.Table tr.heading th:first-child {
  border-left: none;
}
.Table tr.heading th:last-child {
  border-right: 1px solid #ddd;
}
.Table tr.heading .col {
  justify-content: flex-start;
  padding: 0 10px;
  width: 100%;
  color: #454545;
}
.Table tr.heading .col.right {
  text-align: right;
  justify-content: flex-end;
}
.Table tr.heading .col.sorted {
  justify-content: space-between;
  color: #2375bb;
}
.Table tr.heading .sort.up .up-arrow {
  color: #2375bb;
}
.Table tr.heading .sort.up .down-arrow {
  color: #ddd;
}
.Table tr.heading .sort.down .up-arrow {
  color: #ddd;
}
.Table tr.heading .sort.down .down-arrow {
  color: #2375bb;
}
.Table table {
  width: 100%;
}
.Table tr.row {
  border-bottom: 1px solid #ddd;
  position: relative;
  box-shadow: 0 0 0 #999;
  transition: box-shadow 0.3s;
}
.Table tr.row > td {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.Table tr.row:hover {
  box-shadow: 0 2px 6px #999;
}
.Table tr.row:hover .rowPanel {
  opacity: 1;
  z-index: 1;
  justify-content: space-around;
}
.Table tr.row:hover .rowPanel .Icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 100%;
}
.Table tr.row:hover .rowPanel .Icon:hover {
  background-color: rgba(35,117,183,0.1);
}
.Table tr.row:hover .rowPanel .Icon:hover i {
  color: #005394;
}
.Table tr.row.notifyHighlight {
  background-color: #f9f5ec;
}
.Table tr.row.notifyHighlight .Icon:hover {
  background-color: rgba(183,133,57,0.1) !important;
}
.Table tr.row.notifyHighlight .rowPanel {
  background-color: #f9f5ec;
}
.Table tr.row.selected {
  box-shadow: 0 2px 6px #999;
  background-color: #e9f2f9;
}
.Table tr.row.selected .Icon:hover {
  background-color: rgba(35,117,183,0.1) !important;
}
.Table tr.row.selected .rowPanel {
  background-color: #e9f2f9;
}
.Table tr.row .rowPanel {
  position: absolute;
  right: 8px;
  top: 3px;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  background-color: #fff;
  height: 55px;
  width: 212px;
  opacity: 0;
  z-index: -1;
}
.Table tr.row .rowPanel i {
  color: #454545;
}
.Table tr.row td {
  padding: 0;
}
.Table tr.row td .col {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  word-wrap: break-word;
}
.Table tr.row td .col.right {
  text-align: right;
  justify-content: flex-end;
}
.Table tr.row td .col > a {
  color: #2375bb;
  text-decoration: none;
}
.Table tr.row td .col > a:hover {
  text-decoration: underline;
}
.Table .sort {
  font-weight: 900;
  font-size: 15px;
  color: #ddd;
  margin-left: 10px;
}
.Table .sort .up-arrow:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f077";
}
.Table .sort .down-arrow:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f078";
}
.Table .check_col {
  width: 45px;
  padding: 0 !important;
}
.Table .check_col > div {
  width: 20px;
  margin: 0 auto;
}
.Table .Notifier {
  margin: 12px;
}
