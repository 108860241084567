.ReplacementItem .middle,
.ReplacementItem .middle .deleteButton,
.ReplacementItem .price {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SelectReplacementPanel {
  position: fixed;
  top: 80px;
  bottom: 8px;
  left: 32px;
  right: 32px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .SelectReplacementPanel {
    left: 16px;
    right: 16px;
  }
}
.SelectReplacementPanel h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
}
.SelectReplacementPanel > p {
  padding: 24px 0;
}
.ReplacementItem > .inner {
  padding: 16px 0 16px 0;
  display: grid;
  grid-template-columns: 80px 260px 80px;
  border-bottom: 1px solid #ddd;
}
.ReplacementItem .image-wrap {
  padding: 0 8px;
}
.ReplacementItem .image-wrap img {
  width: 65px;
}
.ReplacementItem .product-name {
  margin-top: -4px;
  margin-bottom: 8px;
  line-height: 16px;
  color: #2375bb;
}
.ReplacementItem .id-numbers {
  font-size: 9px;
  color: #454545;
  padding: 0 0 8px 0;
}
@media (max-width: 768px) {
  .ReplacementItem .id-numbers {
    font-size: 9px;
  }
}
.ReplacementItem .spacer {
  padding: 0 10px;
}
.ReplacementItem .middle .NumberInput {
  margin-right: 8px;
}
.ReplacementItem .middle .deleteButton {
  cursor: pointer;
  border-radius: 100%;
  width: 80px;
  height: 40px;
  font-size: 18px;
}
.ReplacementItem .middle .deleteButton:hover {
  background-color: #eee;
}
.ReplacementItem .price {
  justify-content: flex-end;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
}
.ReplacementItem .replaceButton {
  grid-gap: 16px;
}
