@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Search .SearchHeader {
  padding: 32px 8px 32px 8px;
  display: grid;
  grid-template-columns: 1fr 130px;
}
.Search .SearchHeader a {
  color: #2375bb;
  text-decoration: none;
}
.Search .SearchHeader a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .Search .SearchHeader {
    display: none;
  }
}
.Search .SearchHeader h1 {
  font-size: 18px;
  margin-bottom: 16px;
}
.Search .SearchHeader h1 span {
  font-weight: 500;
}
.Search .MobileSearchHeader {
  display: grid;
  grid-template-columns: 1fr;
  padding: 120px 8px 0px 8px;
  display: none;
}
@media (max-width: 768px) {
  .Search .MobileSearchHeader {
    display: block;
  }
}
.Search .MobileSearchHeader h1 {
  font-size: 18px;
}
.Search .MobileSearchHeader h1 span {
  font-weight: 500;
}
.Search .SearchMain {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 16px;
  padding: 0 0 24px 0;
}
@media (max-width: 768px) {
  .Search .SearchMain {
    grid-template-columns: 1fr;
  }
}
.Search .MobileFilters {
  padding: 16px 8px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 16px 8px;
  display: none;
}
@media (max-width: 768px) {
  .Search .MobileFilters {
    display: grid;
    grid-template-columns: 100px 1fr 30px;
    align-items: center;
    grid-gap: 8px;
  }
}
.Search .MobileFilters .Icon {
  cursor: pointer;
}
.Search .SearchFilterPanel {
  background-color: #fff;
}
.Search .SearchFilterPanel a {
  color: #2375bb;
  text-decoration: none;
}
.Search .SearchFilterPanel a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .Search .SearchFilterPanel {
    z-index: 4;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    height: calc(100vh - 56px);
    overflow: scroll;
    transition: top 250ms;
  }
}
.Search .SearchFilterPanel.open {
  top: 56px;
}
.Search .SearchFilterPanel .actionButtons {
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 16px;
}
@media (max-width: 768px) {
  .Search .SearchFilterPanel .actionButtons {
    display: grid;
  }
}
.Search .SearchResults {
  max-width: 960px;
}
.Search .SearchResults > .inner {
  display: grid;
  grid-gap: 16px;
}
.Search .SelectMenuFilters .SelectMenu {
  padding: 8px 0;
}
.Search .PriceBody .inner {
  display: grid;
  grid-template-columns: 10px 70px 10px 70px 80px;
  grid-gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Search .PriceBody .inner span {
  color: #999;
}
.Search .Carousel .ProductListing .product-name {
  min-height: 70px;
}
.Search .ProductRecommendations {
  padding: 24px 36px;
}
@media (max-width: 768px) {
  .Search .ProductRecommendations {
    width: 100vw;
  }
}
.Search .ProductRecommendations .productListHeading {
  text-align: center;
}
.Search.gridView .SearchResults > .inner {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 768px) {
  .Search.gridView .SearchResults > .inner {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .Search.gridView {
    grid-template-columns: 1fr 1fr;
  }
  .Search.gridView .Icon.gridView {
    display: none;
  }
  .Search.gridView .Icon.listView {
    display: block;
  }
}
.Search.listView .SearchResults > .inner {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
}
@media (max-width: 768px) {
  .Search.listView .Icon.gridView {
    display: block;
  }
  .Search.listView .Icon.listView {
    display: none;
  }
}
.Search.listView .ProductListing {
  max-width: 100%;
}
.Search.listView .SearchResults .inner .ProductListing {
  width: 100%;
  display: grid;
  grid-template-columns: 160px 1fr 200px;
  grid-gap: 16px;
  border-bottom: 1px solid #ddd;
}
.Search.listView .SearchResults .inner .ProductListing .id-numbers .group div,
.Search.listView .SearchResults .inner .ProductListing .id-numbers .group span {
  display: inline-block;
  margin-right: 16px;
}
.Search.listView .SearchResults .inner .ProductListing .left {
  padding-right: 8px;
}
.Search.listView .SearchResults .inner .ProductListing .left .image-wrap {
  padding: 8px;
  height: auto;
}
.Search.listView .SearchResults .inner .ProductListing .right {
  height: 80px;
  margin-top: 0px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-around;
}
.Search.listView .SearchResults .inner .ProductListing .right .InStock {
  display: none;
}
@media (max-width: 768px) {
  .Search.listView .SearchResults .inner .ProductListing {
    display: block;
  }
  .Search.listView .SearchResults .inner .ProductListing .Carousel .ProductListing {
    max-width: 100%;
  }
  .Search.listView .SearchResults .inner .ProductListing .left,
  .Search.listView .SearchResults .inner .ProductListing .mid,
  .Search.listView .SearchResults .inner .ProductListing .right {
    float: left;
  }
  .Search.listView .SearchResults .inner .ProductListing .left {
    width: 40%;
    height: 100%;
  }
  .Search.listView .SearchResults .inner .ProductListing .mid,
  .Search.listView .SearchResults .inner .ProductListing .right {
    width: 60%;
  }
}
