@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CartSettingsPanel {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 32px;
  background-color: #fff;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .CartSettingsPanel {
    top: 70px;
    left: 16px;
    right: 16px;
    padding: 0;
  }
}
.CartSettingsPanel h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
  left: 32px;
  right: 32px;
}
@media (max-width: 768px) {
  .CartSettingsPanel h2 {
    left: 16px;
    right: 16px;
  }
}
.CartSettingsPanel .TextInput {
  padding: 16px 0;
}
.CartSettingsPanel fieldset {
  border: none;
  padding: 32px 0;
}
.CartSettingsPanel fieldset label {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
}
.CartSettingsPanel .RadioElement label {
  font-weight: normal;
}
.CartSettingsPanel .buttons {
  display: flex;
  justify-content: flex-end;
}
.CartSettingsPanel .buttons .Button {
  margin-left: 8px;
}
