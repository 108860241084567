.MarkupInput {
  display: grid;
  grid-template-columns: 50% 50%;
}
.MarkupInput .TextInput input {
  text-align: right;
}
.MarkupInput .Select__dropdown-indicator {
  width: 20px;
  padding: 2px;
}
