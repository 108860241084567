@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
.SuggestedProducts .Loading .image-wrap,
.SuggestedProducts .Loading .heading,
.SuggestedProducts .Loading .heading-end,
.SuggestedProducts .Loading .id-numbers span.num {
  background-color: #ddd;
  border-radius: 6px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eee 10%, #ddd 20%, #eee 30%);
  background-size: 800px 104px;
  position: relative;
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SuggestedProducts {
  padding: 8px 0 0 8px;
}
.SuggestedProducts h3 {
  font-weight: bold;
  color: #454545;
  padding: 0 8px;
  font-size: 14px;
}
.SuggestedProducts .list {
  padding: 8px 0;
  margin: 4px 0;
}
.SuggestedProducts .Loading {
  display: grid;
  grid-template-columns: 70px 1fr;
  padding: 8px;
  grid-gap: 16px;
  width: 82%;
}
.SuggestedProducts .Loading .group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.SuggestedProducts .Loading .image-wrap {
  height: 55px;
  width: 100%;
}
.SuggestedProducts .Loading .heading {
  height: 12px;
  width: 100%;
  margin-bottom: 6px;
}
.SuggestedProducts .Loading .heading-end {
  height: 12px;
  width: 30%;
  margin-bottom: 6px;
}
.SuggestedProducts .Loading .id-numbers {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  width: 40%;
}
.SuggestedProducts .Loading .id-numbers span.num {
  height: 12px;
  width: 100px;
}
