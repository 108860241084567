.Notifier .close {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Notifier {
  border: 1px solid #000;
  display: none;
}
.Notifier a {
  color: #2375bb;
}
@media (max-width: 768px) {
  .Notifier .Icon {
    align-items: flex-start;
  }
}
.Notifier .innerNotifier {
  position: relative;
  display: grid;
  grid-template-columns: 60px 1fr;
  padding: 12px 48px 12px 0;
}
.Notifier.open {
  display: block;
}
.Notifier.success {
  background-color: rgba(11,118,48,0.1);
  border-color: #0b7630;
}
.Notifier.success .Icon i,
.Notifier.success .close,
.Notifier.success .heading {
  color: #0b7630;
}
.Notifier.success .close:hover {
  background-color: rgba(11,118,48,0.1);
}
.Notifier.warn {
  background-color: rgba(183,183,57,0.1);
  border-color: #eba90c;
}
.Notifier.warn .Icon i,
.Notifier.warn .close,
.Notifier.warn .heading {
  color: #eba90c;
}
.Notifier.warn .close:hover {
  background-color: rgba(183,183,57,0.1);
}
.Notifier.info {
  background-color: rgba(35,117,183,0.1);
  border-color: #2375bb;
}
.Notifier.info .Icon i,
.Notifier.info .close,
.Notifier.info .heading {
  color: #2375bb;
}
.Notifier.info .close:hover {
  background-color: rgba(35,117,183,0.1);
}
.Notifier.error {
  background-color: rgba(195,61,57,0.1);
  border-color: #c33d39;
}
.Notifier.error .Icon i,
.Notifier.error .close,
.Notifier.error .heading {
  color: #c33d39;
}
.Notifier.error a {
  color: #2375bb;
}
.Notifier.error .close:hover {
  background-color: rgba(195,61,57,0.1);
}
.Notifier .heading {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin: 0 8px 0 0;
}
.Notifier .close {
  position: absolute;
  display: flex;
  height: 20px;
  width: 20px;
  font-size: 26px;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
.Notifier .text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 20px;
  font-size: 14px;
}
@media (max-width: 768px) {
  .Notifier .text {
    align-items: flex-start;
    flex-flow: column;
  }
}
