.SummaryItem .price {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SummaryItem {
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .SummaryItem {
    padding: 0;
  }
}
.SummaryItem > .inner {
  padding: 0;
  display: grid;
  grid-template-columns: 80px 1fr 100px;
}
@media (max-width: 768px) {
  .SummaryItem > .inner {
    grid-template-columns: 80px 1fr 80px;
  }
}
.SummaryItem .image-wrap {
  padding: 0 8px 0 0;
  overflow: hidden;
  max-height: 65px;
}
.SummaryItem .image-wrap img {
  width: 65px;
  max-height: 65px;
  font-size: 8px;
}
.SummaryItem .product-name {
  margin-bottom: 8px;
  line-height: 16px;
  color: #2375bb;
}
.SummaryItem .id-numbers {
  font-size: 11px;
  color: #454545;
  padding: 0 0 8px 0;
}
.SummaryItem .id-numbers .line {
  display: inline;
}
@media (max-width: 768px) {
  .SummaryItem .id-numbers {
    font-size: 9px;
  }
  .SummaryItem .id-numbers .line {
    display: block;
  }
}
.SummaryItem .spacer {
  padding: 0 10px;
}
@media (max-width: 768px) {
  .SummaryItem .spacer {
    display: none;
  }
}
.SummaryItem .price {
  justify-content: flex-end;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
}
.SummaryItem .qty {
  display: flex;
  font-size: 14px;
  justify-content: right;
}
.SummaryItem a.action {
  text-align: right;
  display: block;
  color: #2375bb;
  text-decoration: none;
}
.SummaryItem a.action:hover {
  text-decoration: underline;
}
