@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CartItems {
  padding: 0;
}
.CartItems .productFlags {
  padding: 16px 0;
  width: 100%;
}
.CartItems .productFlags .ProductFlag {
  margin-right: 8px;
}
@media (min-width: 1280px) {
  .CartItems {
    width: 1280px;
  }
}
@media (max-width: 768px) {
  .CartItems {
    width: 100%;
  }
}
.CartItems > label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
}
@media (max-width: 768px) {
  .CartItems .SearchElement,
  .CartItems > label {
    padding: 0 4px;
  }
}
.CartItems .Table tr.row {
  display: flex;
  padding: 16px 0 0;
}
.CartItems .Table tr.row .CartitemPanel {
  display: none;
  bottom: 20px;
  top: auto;
}
.CartItems .Table tr.row:hover .CartitemPanel {
  display: flex;
}
.CartItems .Table tr.row .col {
  align-items: flex-start;
}
.CartItems .Table tr.heading .headingActions {
  position: relative;
  width: 150px;
}
.CartItems .Table td,
.CartItems .Table th {
  display: flex;
  justify-content: flex-end;
}
.CartItems .Table .col {
  align-items: flex-start;
  padding: 26px 8px;
}
.CartItems .Table .check_col {
  align-items: center;
}
.CartItems .Table .item_desc {
  justify-content: flex-start;
  width: 46%;
}
.CartItems .Table .warehouse {
  justify-content: flex-start;
  width: 18%;
}
.CartItems .Table .user_qty {
  width: 12%;
}
.CartItems .Table .user_price {
  width: 12%;
}
.CartItems .Table .line_price {
  width: 12%;
}
.CartItems .in-stock {
  flex-flow: column;
}
.CartItems .in-stock a {
  color: #2375bb;
  text-decoration: none;
}
.CartItems .InStock {
  padding: 0 0 8px 0;
}
.CartItems .MobileTable {
  margin-top: 16px;
}
.CartItems .MobileTable .infoItem {
  display: grid;
  grid-template-columns: 65% 35%;
  width: 100%;
  line-height: 32px;
  padding: 8px 4px;
}
@media (max-width: 768px) {
  .CartItems .MobileTable .infoItem .left,
  .CartItems .MobileTable .infoItem .right {
    padding: 0;
  }
}
.CartItems .MobileTable .infoItem > span {
  color: #454545;
  font-size: 14px;
}
.CartItems .notes {
  padding-top: 16px;
}
@media (max-width: 768px) {
  .CartItems .notes {
    padding: 16px 4px 0 4px;
  }
}
.CartItems .priceTrending {
  display: grid;
  grid-template-columns: 33% 66%;
  align-items: flex-start;
}
.CartItems .priceTrending .Icon {
  margin-right: 8px;
}
.CartItems .priceTrending .right {
  text-align: right;
}
@media (max-width: 768px) {
  .CartItems .priceTrending {
    float: right;
    width: 110px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
  .CartItems .priceTrending .Icon {
    width: 40px;
  }
}
.CartItemInfoDescription .Notifier {
  margin-bottom: 16px;
}
.CartItemInfoDescription .ProductFlag {
  margin-right: 8px;
}
.CartItemInfoDescription .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 320px;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .Table {
    display: none;
  }
}
