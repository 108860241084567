@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CartHeader {
  background-color: #ddd;
  padding: 0 16px 24px 16px;
}
.CartHeader .cartIcon i {
  font-size: 36px;
}
.CartHeader .CartName h1,
.CartHeader .CartName span {
  font-size: 36px;
}
.CartHeader .MobileCartHeader {
  padding: 5px 0 0 0;
  display: none;
}
@media (max-width: 768px) {
  .CartHeader {
    padding: 0 16px 8px 16px;
  }
  .CartHeader .cartIcon i {
    font-size: 22px;
  }
  .CartHeader .CartName h1,
  .CartHeader .CartName span {
    font-size: 22px;
  }
  .CartHeader .DesktopCartHeader {
    display: none;
  }
  .CartHeader .MobileCartHeader {
    display: block;
  }
  .CartHeader .MobileCartHeader .top {
    width: 172px;
    margin: 8px auto;
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}
.CartHeader .cartHeaderActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 205px;
}
@media (max-width: 768px) {
  .CartHeader .cartHeaderActions {
    display: none;
  }
}
.CartHeader .CartActionButtons {
  display: block;
  width: 210px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .CartHeader .CartActionButtons {
    display: none;
  }
}
.CartHeader > .inner {
  max-width: 1280px;
  margin: 0 auto;
}
.CartHeader h1 {
  font-weight: 100;
}
.CartHeader .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.CartHeader .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 768px) {
  .CartHeader {
    padding-top: 100px;
    grid-template-columns: 1fr;
  }
  .CartHeader .left {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    margin: 0 auto;
    justify-content: flex-start;
  }
  .CartHeader .right {
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.CartHeader .cartIcon {
  margin-right: 16px;
}
