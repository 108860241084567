.CartActions .subtotal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CartActions {
  padding: 32px 0;
}
@media (max-width: 768px) {
  .CartActions {
    padding: 0;
  }
}
.CartActions > .inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2px;
}
@media (max-width: 768px) {
  .CartActions > .inner {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .CartActions > .inner .left {
    display: none;
  }
}
.CartActions > .inner .right {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .CartActions > .inner .right {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .CartActions > .inner .right .Button {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .CartActions > .inner .right .Button {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .CartActions > .inner .mobileFixed {
    z-index: 2;
    display: grid;
    grid-template-columns: 30% 70%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    border-top: 1px solid #eee;
  }
  .CartActions > .inner .mobileFixed .mobileTotal {
    padding: 0;
  }
  .CartActions > .inner .mobileFixed .Button {
    width: 100%;
  }
  .CartActions > .inner .mobileFixed span {
    font-size: 14px;
  }
  .CartActions > .inner .mobileFixed .currency {
    font-weight: bold;
  }
}
.CartActions > .inner .mobileTotal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 768px) {
  .CartActions > .inner .mobileTotal {
    padding: 0px;
    grid-template-columns: 1fr;
    justify-content: flex-start;
  }
}
.CartActions .subtotal {
  margin-right: 8px;
  font-size: 20px;
}
@media (max-width: 768px) {
  .CartActions .subtotal {
    justify-content: flex-start;
  }
}
