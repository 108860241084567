@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Tooltip {
  position: absolute;
  z-index: 9;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  top: -9999px;
  left: -9999px;
}
.Tooltip.white {
  background-color: #fff;
  box-shadow: 0 1px 4px #999;
}
.Tooltip.light {
  background-color: #ddd;
  box-shadow: 0 1px 4px #454545;
}
.Tooltip.dark {
  background-color: #454545;
  box-shadow: 0;
  color: #fff;
}
.Tooltip:before {
  display: block;
  position: absolute;
  height: 30px;
  width: 40px;
  text-align: center;
  font-size: 10px;
  z-index: 1;
}
.Tooltip .cover {
  position: absolute;
  display: block;
  z-index: 2;
}
.Tooltip.white:before {
  color: #fff;
  text-shadow: 0px 1px 4px #999;
}
.Tooltip.light:before {
  color: #ddd;
  text-shadow: 0px 1px 4px #454545;
}
.Tooltip.dark:before {
  color: #454545;
}
.Tooltip.white .cover {
  background-color: #fff;
}
.Tooltip.light .cover {
  background-color: #ddd;
}
.Tooltip.dark .cover {
  background-color: #454545;
}
.Tooltip.above:before {
  content: '▼';
  left: 50%;
  top: 100%;
  margin: -4px 0 0 -20px;
}
.Tooltip.above .cover {
  height: 4px;
  width: 10px;
  top: 100%;
  left: 50%;
  margin: -4px 0 0 -5px;
}
.Tooltip.below:before {
  content: "▲";
  bottom: 100%;
  left: 50%;
  margin: 0 0 -20px -20px;
}
.Tooltip.below .cover {
  height: 4px;
  width: 10px;
  top: 0px;
  left: 50%;
  margin: 0 0 0 -5px;
}
.Tooltip.right:before {
  content: "◀";
  right: 100%;
  top: 50%;
  margin: -8px -18px 0 0;
}
.Tooltip.right .cover {
  height: 10px;
  width: 4px;
  right: 100%;
  top: 50%;
  margin: -5px -4px 0 0;
}
.Tooltip.left:before {
  content: "▶";
  left: 100%;
  top: 50%;
  margin: -5px 0 0 -17px;
}
.Tooltip.left .cover {
  top: 50%;
  right: 0%;
  height: 10px;
  width: 4px;
  margin: -5px 0 0 0;
}
