@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Footer {
  color: #000;
  background-color: #ddd;
}
.Footer .inner {
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width: 768px) {
  .Footer .inner {
    grid-template-columns: 1fr;
    padding: 20px 20px;
  }
  .Footer .inner .social .icons {
    width: 100%;
  }
}
.Footer a {
  text-decoration: none;
}
.Footer a i {
  color: #454545;
  font-size: 30px;
}
.Footer h3 {
  color: #454545;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 16px;
  text-transform: uppercase;
}
.Footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.Footer ul li {
  line-height: 40px;
}
.Footer ul a {
  color: #2375bb;
}
.Footer .social {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .Footer .social {
    margin-top: 16px;
  }
}
.Footer .social .icons {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 180px;
}
.Footer .footerLinks {
  display: grid;
  grid-template-columns: 1fr;
}
@media (max-width: 768px) {
  .Footer .footerLinks {
    grid-template-columns: 1fr 1fr;
  }
}
.Footer .botLine {
  padding: 20px 0;
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 1280px;
  color: #000;
}
@media (max-width: 768px) {
  .Footer .botLine {
    padding: 20px;
  }
}
.Footer .botLine .copyright {
  text-align: left;
}
.Footer .botLine .botLinks {
  text-align: right;
}
.Footer .botLine .botLinks a {
  color: #2375bb;
}
.Footer .botLine .botLinks span {
  margin: 0 10px;
}
