@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CheckoutHeader .topLine {
  background-color: #2375bb;
  height: 40px;
  width: 100%;
}
.CheckoutHeader .topLine .right {
  margin: 0 auto;
  width: 1280px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: right;
  align-items: center;
}
.CheckoutHeader .topLine .right a {
  margin: 0 5px;
  color: #fff;
  text-decoration: none;
}
.CheckoutHeader .topLine .right a:hover {
  text-decoration: underline;
}
.CheckoutHeader .midLine,
.CheckoutHeader .botLine {
  padding: 0px 0;
  width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine,
  .CheckoutHeader .botLine {
    grid-template-columns: 1fr;
    width: 100%;
    font-size: 12px;
    margin-bottom: 0;
  }
}
.CheckoutHeader .midLine .heading,
.CheckoutHeader .botLine .heading {
  font-weight: 100;
  display: flex;
  align-items: flex-end;
  height: 52px;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine .heading,
  .CheckoutHeader .botLine .heading {
    height: 36px;
    color: #fff;
    position: absolute;
    top: 2px;
    left: 156px;
    font-size: 30px;
  }
}
.CheckoutHeader .midLine .logo,
.CheckoutHeader .botLine .logo {
  color: #2375bb;
  width: 240px;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine .logo,
  .CheckoutHeader .botLine .logo {
    color: #fff;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 140px;
  }
}
.CheckoutHeader .midLine .left,
.CheckoutHeader .botLine .left {
  display: grid;
  grid-template-columns: 240px 1fr;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine .left,
  .CheckoutHeader .botLine .left {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.CheckoutHeader .midLine .right,
.CheckoutHeader .botLine .right {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  flex-flow: column;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine .right,
  .CheckoutHeader .botLine .right {
    justify-content: center;
    display: block;
  }
  .CheckoutHeader .midLine .right h2,
  .CheckoutHeader .botLine .right h2 {
    text-align: center;
  }
  .CheckoutHeader .midLine .right p,
  .CheckoutHeader .botLine .right p {
    font-size: 14px;
    text-align: center;
  }
}
.CheckoutHeader .midLine .right a,
.CheckoutHeader .botLine .right a {
  color: #2375bb;
}
.CheckoutHeader .midLine {
  margin-top: 40px;
}
@media (max-width: 768px) {
  .CheckoutHeader .midLine {
    margin-top: 8px;
    padding: 0 24px;
  }
}
.CheckoutHeader .botLine {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .CheckoutHeader .botLine {
    margin-bottom: 0;
    padding: 0 24px;
  }
}
.CheckoutHeader .botLine .right {
  flex-flow: row;
}
.CheckoutHeader .botLine .right .Button {
  width: 140px;
}
@media (max-width: 768px) {
  .CheckoutHeader .botLine .right {
    font-size: 12px;
  }
}
