@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Pagination {
  font-size: 14px;
  min-height: 40px;
  padding: 8px 0;
  display: grid;
  grid-template-columns: 220px 1fr 320px;
  grid-gap: 8px;
}
.Pagination i {
  color: #454545;
}
.Pagination > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 32px;
}
@media (max-width: 768px) {
  .Pagination {
    grid-gap: 16px 0;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .Pagination .showing {
    margin: 0 auto;
  }
}
.Pagination .perPage {
  display: flex;
  margin: 0 auto;
  width: 200px;
  justify-content: flex-start;
  align-items: center;
}
.Pagination .perPage .Select {
  width: 80px;
}
.Pagination .perPage span {
  text-align: right;
}
.Pagination .perPage label {
  display: none;
}
.Pagination .gotoPage {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Pagination .gotoPage span {
  text-align: right;
}
.Pagination .gotoPage .TextInput {
  margin: 0 8px;
}
.Pagination .gotoPage .TextInput input {
  width: 40px;
}
.Pagination .gotoPage a.Button {
  width: 40px;
  line-height: 36px;
}
.Pagination .numbers {
  font-weight: bold;
}
.Pagination .numbers span {
  cursor: pointer;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 5px;
}
.Pagination .numbers span.active,
.Pagination .numbers span:hover {
  background-color: #2375bb;
  color: #fff;
}
.Pagination .pageNav {
  justify-content: space-between;
  width: 320px;
}
@media (max-width: 768px) {
  .Pagination .pageNav {
    margin: 0 auto;
  }
}
.Pagination .pageNav .pageButton {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.Pagination .pageNav .pageButton:hover {
  border: 1px solid #2375bb;
}
.Pagination .pageNav .pageButton:hover .Icon i {
  color: #005394;
}
.Pagination .pageNav .prevAll:hover .Icon i,
.Pagination .pageNav .nextAll:hover .Icon i {
  color: #005394;
}
.Pagination .pageNav .prevAll .Icon,
.Pagination .pageNav .nextAll .Icon {
  float: left;
  margin: 0 -2px;
}
