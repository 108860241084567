@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SplitLinePanel {
  position: fixed;
  top: 80px;
  bottom: 8px;
  left: 32px;
  right: 32px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .SplitLinePanel {
    left: 16px;
    right: 16px;
  }
}
.SplitLinePanel > h2 {
  font-size: 24px;
  border-bottom: 1px solid #999;
  padding-bottom: 16px;
}
.SplitLinePanel p.info {
  padding: 16px 0 0 0;
}
.SplitLinePanel .productFlags {
  margin-top: 12px;
}
.SplitLinePanel .productFlags .ProductFlag {
  margin-right: 8px;
}
.SplitLinePanel .Table .quantity {
  width: 25%;
}
.SplitLinePanel .Table .warehouse {
  width: 50%;
}
.SplitLinePanel .Table .in_stock {
  width: 25%;
}
.SplitLinePanel .Table .in_stock .col {
  justify-content: flex-end;
}
.SplitLinePanel .buttons {
  padding: 32px 0;
  display: flex;
  justify-content: flex-end;
}
.SplitLinePanel .buttons .Button {
  margin-left: 8px;
}
.SplitLinePanel .CartItemInfo {
  padding: 24px 0;
}
