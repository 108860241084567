@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteTotals {
  padding-bottom: 24px;
  font-size: 14px;
}
.QuoteTotals .totalLine {
  padding: 16px;
  border-bottom: 1px solid #ddd;
}
.QuoteTotals .totalLine.doublePadd {
  padding: 16px 56px 16px 16px;
}
.QuoteTotals .totalLine:last-of-type {
  border-bottom: none;
}
.QuoteTotals .totalLine .left {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
}
.QuoteTotals .totalLine .right {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}
.QuoteTotals .col2 {
  grid-template-columns: 1fr 1fr;
}
.QuoteTotals .totalButton {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.QuoteTotals .totalButton .right {
  text-align: right;
}
.QuoteTotals .visible {
  padding: 16px;
  background-color: rgba(35,117,183,0.1);
}
.QuoteTotals .hidden {
  display: block;
  overflow: hidden;
  padding: 0;
  height: 0;
  transition: height 400ms;
}
.QuoteTotals .Accordion.open .hidden {
  height: 213px;
}
@media (max-width: 768px) {
  .QuoteTotals .totalLine {
    padding: 16px;
  }
}
