@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.MobileHeader {
  display: none;
  z-index: 4;
  position: fixed;
  width: 100%;
  height: 86px;
  box-sizing: border-box;
}
.MobileHeader > .inner {
  width: 100%;
  background-color: #2375bb;
  color: #fff;
  height: 56px;
  z-index: 2;
  position: relative;
}
.MobileHeader .logo {
  position: relative;
  top: 14px;
  left: 12px;
  color: #fff;
  width: 130px;
}
.MobileHeader .logo img {
  width: inherit;
}
.MobileHeader .icons {
  position: absolute;
  right: 4px;
  top: 8px;
  width: 120px;
  display: flex;
  flex-flow: row;
}
.MobileHeader .icons .Icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.MobileHeader .mobileSlider {
  background-color: #f8f8f8;
  z-index: 1;
}
.MobileHeader .mobileSlider .close {
  display: none;
}
.MobileHeader .MobileSearch {
  background-color: #fff;
  box-shadow: 0 2px 4px #ddd;
  display: flex;
  border-radius: 0;
  padding: 4px 12px;
}
.MobileHeader .MobileSearch .TextInput {
  width: 100%;
}
.MobileHeader .MobileSearch .TextInput input {
  padding: 0 12px;
  border: none;
}
.MobileHeader .MobileSearch .TextInput input:focus {
  box-shadow: none;
  outline: none;
}
@media (max-width: 768px) {
  .Header {
    display: none;
  }
  .MobileHeader {
    display: block;
  }
}
