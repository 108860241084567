@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.MobileMenu {
  position: relative;
  min-height: 100vh;
  transition: transform 350ms;
}
.MobileMenu .panel {
  background-color: #fff;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.MobileMenu .level2 {
  transform: translateX(100vw);
}
.MobileMenu.level-2 {
  transform: translateX(-100vw);
}
.MobileMenu.panel-home .home,
.MobileMenu.panel-account .account {
  z-index: 2;
}
.MobileMenu .menuLink {
  cursor: pointer;
}
.MobileMenuLink {
  display: flex;
  height: 60px;
  align-items: center;
  text-decoration: none;
  padding-left: 16px;
  color: #000;
}
.MobileMenuItem,
.MobileMenuHeading {
  display: grid;
  grid-template-columns: 1fr 60px;
  height: 60px;
  align-items: center;
  padding-left: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 0.5px solid #ddd;
  cursor: pointer;
}
.MobileMenuItem.account,
.MobileMenuHeading.account {
  background-color: #eee;
}
.MobileMenuItem.account > .inner,
.MobileMenuHeading.account > .inner {
  flex-flow: row;
  display: flex;
  height: 60px;
  align-items: center;
}
.MobileMenuItem.account > .inner .Icon,
.MobileMenuHeading.account > .inner .Icon {
  margin-right: 16px;
}
.MobileMenuHeading {
  background-color: #eee;
  grid-template-columns: 40px 1fr;
  text-align: center;
}
.MobileMenuHeading .Icon {
  justify-content: flex-start;
}
