.Icon,
.Icon i {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.Icon {
  font-size: 20px;
}
.Icon.rounded {
  border-radius: 100%;
}
.Icon i {
  color: #454545;
}
.Icon i.orange {
  color: #e1500b;
}
.Icon i.red {
  color: #c33d39;
}
.Icon i.blue {
  color: #2375bb;
}
.Icon i.orange:hover {
  color: #cb4708;
}
.Icon i.blue:hover {
  color: #005394;
}
.Icon i.yellow {
  color: #eba90c;
}
.Icon i.green {
  color: #085c26;
}
.Icon i.purple {
  color: #970985;
}
.Icon i.grey0 {
  color: #333;
}
.Icon i.grey1 {
  color: #454545;
}
.Icon i.grey2 {
  color: #999;
}
.Icon i.grey3 {
  color: #ddd;
}
.Icon i.grey4 {
  color: #eee;
}
.Icon i.grey5 {
  color: #f8f8f8;
}
.Icon i.white {
  color: #fff;
}
.Icon i.fGreen {
  color: #b9e18b;
}
.Icon i.fRed {
  color: #ffbdbb;
}
.Icon i.fOrange {
  color: #fbc3a9;
}
.Icon i.fYellow {
  color: #f8e1aa;
}
.Icon i.fBlue {
  color: #cbe0f9;
}
.Icon i.tGreen {
  color: rgba(11,118,48,0.1);
}
.Icon i.tBlue {
  color: rgba(35,117,183,0.1);
}
.Icon i.tRed {
  color: rgba(195,61,57,0.1);
}
