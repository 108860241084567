@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CheckoutSection {
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .CheckoutSection {
    margin: 8px 2px;
  }
}
.CheckoutSection > .inner {
  position: relative;
  padding: 24px;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 768px) {
  .CheckoutSection > .inner {
    padding: 8px;
  }
}
.CheckoutSection > .inner p {
  margin: 8px 0;
  font-size: 14px;
}
.CheckoutSection > .inner h1 {
  font-size: 22px;
}
.CheckoutSection > .inner h4 {
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  letter-spacing: 0.5px;
}
.CheckoutSection a.action {
  text-decoration: none;
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: #2375bb;
}
.CheckoutSection a.action:hover {
  text-decoration: underline;
}
.CheckoutSection .paymentType {
  margin-top: 16px;
}
.CheckoutSection .deliveryOption .RadioElement {
  margin: 8px 0;
}
.CheckoutSection .carrierMethod .box {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid #ddd;
  justify-content: space-around;
  display: grid;
  grid-template-columns: 31% 18% 18% 18%;
  text-align: center;
}
@media (max-width: 768px) {
  .CheckoutSection .carrierMethod .box {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
.CheckoutSection .others {
  padding: 8px 0;
}
