.CheckBox input[type="checkbox"]:checked + label::before,
.CheckBox input[type="checkbox"][checked="checked"] + label::before {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.CheckBox {
  position: relative;
}
.CheckBox input[type="checkbox"] {
  left: -99999px;
  opacity: 0;
  position: absolute;
}
.CheckBox label {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  padding-left: 35px;
  vertical-align: middle;
}
.CheckBox label::before {
  background: #fff;
  border: 2px solid #999;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
}
.CheckBox input[type="checkbox"]:focus + label::before,
.CheckBox input[type="checkbox"].focus + label::before {
  -webkit-box-shadow: 0 0 8px #2375bb;
  -moz-box-shadow: 0 0 8px #2375bb;
  box-shadow: 0 0 8px #2375bb;
  outline: none;
}
.CheckBox input[type="checkbox"]:checked + label::before,
.CheckBox input[type="checkbox"][checked="checked"] + label::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  color: #2375bb;
  font-size: 11px;
}
