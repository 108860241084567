@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
.NotificationCount .inner.up {
  animation: sweller 750ms;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.NotificationCount .inner {
  position: relative;
  z-index: 2;
  background-color: #c33d39;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  font-size: 9px;
  color: #fff;
  padding: 2px 4px;
  position: absolute;
  box-shadow: -2px 2px 2px rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10px 0 0 10px;
}
@media (max-width: 768px) {
  .NotificationCount .inner {
    margin: -38px 0 0 24px;
  }
}
.NotificationCount .inner.up {
  animation-delay: 500ms;
}
.NotificationCount .explosion {
  z-index: 0;
  position: absolute;
  height: 100px;
  width: 100px;
  margin: -61px 0 0 -46px;
  top: 50%;
  left: 50%;
  background: url("/images/explosion.png") no-repeat;
  background-position: 2800px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NotificationCount .explosion.boom {
  background-position: -2800px 0;
  transition: background 1s steps(56);
}
.NotificationCount .explosion .NotificationCount {
  z-index: 2;
}
@media (max-width: 768px) {
  .NotificationCount .explosion {
    margin: -62px 0 0px -40px;
    display: none;
  }
}
