@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
.QuoteLinesSuggestions .LoadingSkeleton .image-wrap,
.QuoteLinesSuggestions .LoadingSkeleton .heading,
.QuoteLinesSuggestions .LoadingSkeleton .heading-end,
.QuoteLinesSuggestions .LoadingSkeleton .id-numbers span.num,
.QuoteLinesSuggestions .LoadingSkeleton .price span,
.QuoteLinesSuggestions .LoadingSkeleton .number-input span {
  background-color: #ddd;
  border-radius: 6px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eee 10%, #ddd 20%, #eee 30%);
  background-size: 800px 104px;
  position: relative;
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteLinesSuggestions {
  margin-top: 2px;
  width: 712px;
  padding: 8px 8px 16px 8px;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background-color: #fff;
}
@media (max-width: 768px) {
  .QuoteLinesSuggestions {
    width: 100%;
  }
}
.QuoteLinesSuggestions .list {
  padding: 8px 0;
  margin: 4px 0;
}
.QuoteLinesSuggestions .LoadingSkeleton {
  display: grid;
  grid-template-columns: 80px 1fr 80px 185px;
  grid-gap: 24px;
  padding: 16px;
}
@media (max-width: 768px) {
  .QuoteLinesSuggestions .LoadingSkeleton {
    width: 100%;
    grid-template-columns: 80px 1fr;
    grid-gap: 24px;
  }
}
.QuoteLinesSuggestions .LoadingSkeleton .group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.QuoteLinesSuggestions .LoadingSkeleton .image-wrap {
  height: 55px;
  width: 100%;
}
.QuoteLinesSuggestions .LoadingSkeleton .heading {
  height: 12px;
  width: 100%;
  margin-bottom: 6px;
}
.QuoteLinesSuggestions .LoadingSkeleton .heading-end {
  height: 12px;
  width: 30%;
  margin-bottom: 6px;
}
.QuoteLinesSuggestions .LoadingSkeleton .id-numbers {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  width: 40%;
}
.QuoteLinesSuggestions .LoadingSkeleton .id-numbers span.num {
  height: 12px;
  width: 100px;
}
.QuoteLinesSuggestions .LoadingSkeleton .price {
  display: flex;
  align-items: center;
}
.QuoteLinesSuggestions .LoadingSkeleton .price span {
  display: inline-block;
  height: 12px;
  width: 60px;
}
.QuoteLinesSuggestions .LoadingSkeleton .number-input .col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.QuoteLinesSuggestions .LoadingSkeleton .number-input span {
  display: inline-block;
  height: 40px;
  width: 82px;
}
