@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.NumberInput {
  position: relative;
}
.NumberInput > label {
  color: #333;
  display: block;
  font: 0.875rem "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin-bottom: 7px;
}
.NumberInput .input {
  color: #333;
  background: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  line-height: 36px;
  height: 40px;
  padding: 0 24px 0 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
}
@media (max-width: 768px) {
  .NumberInput .input {
    text-align: center;
    font-size: 18px;
    padding: 0 30px;
  }
}
.NumberInput.short .input {
  line-height: 26px;
}
.NumberInput .input:disabled,
.NumberInput .input[disabled="disabled"] {
  background: #ddd;
}
.NumberInput .input:focus,
.NumberInput .input.error:focus,
.NumberInput .input.focus {
  -webkit-box-shadow: 0 0 8px #2375bb;
  outline: none;
}
.NumberInput .input:focus -moz-box-shadow 0 0 8px $cBlue,
.NumberInput .input.error:focus -moz-box-shadow 0 0 8px $cBlue,
.NumberInput .input.focus -moz-box-shadow 0 0 8px $cBlue {
  box-shadow: 0 0 8px #2375bb;
}
.NumberInput ::-webkit-input-placeholder {
  color: #999;
}
.NumberInput :-ms-input-placeholder {
  color: #999;
}
.NumberInput ::-ms-input-placeholder {
  color: #999;
}
.NumberInput ::placeholder {
  color: #999;
}
.NumberInput .input.error {
  background: #fff;
  border: 1px solid #c33d39;
  -webkit-box-shadow: 0 0 8px #c33d39;
  padding-right: 40px;
}
.NumberInput .input.error -moz-box-shadow 0 0 8px $cRed {
  box-shadow: 0 0 8px #c33d39;
}
.NumberInput .error-description {
  color: #c33d39;
  font-size: 0.875rem;
  margin: 7px 0 0;
}
.NumberInput .arrowButtons {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-flow: column;
  right: 1px;
  width: 20px;
  top: 1px;
  bottom: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (max-width: 768px) {
  .NumberInput .arrowButtons {
    display: none;
  }
}
.NumberInput .mobile {
  display: none;
}
@media (max-width: 768px) {
  .NumberInput .mobile {
    display: block;
  }
}
.NumberInput .mobile .plus {
  cursor: pointer;
  position: absolute;
  right: 1px;
  width: 30px;
  top: 1px;
  bottom: 1px;
}
.NumberInput .mobile .minus {
  cursor: pointer;
  position: absolute;
  left: 1px;
  width: 30px;
  top: 1px;
  bottom: 1px;
}
.NumberInput .Icon {
  width: 20px;
  border-radius: 6px;
}
.NumberInput .Icon:hover {
  background-color: #eee;
}
.NumberInput i {
  font-size: 14px;
}
