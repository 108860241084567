@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.ProductSuggestion {
  color: #000;
  font-size: 12px;
  padding: 8px;
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 16px;
}
.ProductSuggestion .group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ProductSuggestion .price {
  display: flex;
  align-items: top;
  justify-content: flex-start;
}
.ProductSuggestion .info {
  display: flex;
  flex-flow: column;
}
.ProductSuggestion .image-wrap {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.ProductSuggestion .image-wrap img {
  max-height: 60px;
  width: 100%;
}
.ProductSuggestion .in-stock {
  color: #0b7630;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
}
.ProductSuggestion .in-stock:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 900;
  content: "\f078";
  margin-left: 4px;
}
.ProductSuggestion .product-name {
  margin-top: -4px;
  margin-bottom: 8px;
  line-height: 16px;
  font-size: 12px;
  max-width: 80%;
  color: #2375bb;
}
.ProductSuggestion .id-numbers {
  font-size: 12px;
  color: #999;
  padding: 0;
  text-align: left;
}
.ProductSuggestion .spacer {
  padding: 0 10px;
}
.ProductSuggestion .numberInput .NumberInput {
  width: 100%;
}
.ProductSuggestion .numberInput .NumberInput .col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
@media (max-width: 768px) {
  .ProductSuggestion {
    grid-template-columns: 80px 1fr;
    grid-gap: 24px;
  }
  .ProductSuggestion .showOnHover {
    display: grid;
  }
}
.ProductSuggestion .col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
