@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.ProductSuggestionAdder {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  padding: 16px;
  display: grid;
  grid-template-columns: 80px 1fr 80px 185px;
  grid-gap: 24px;
}
.ProductSuggestionAdder .group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ProductSuggestionAdder .price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ProductSuggestionAdder .info {
  display: flex;
  flex-flow: column;
}
.ProductSuggestionAdder .image-wrap {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.ProductSuggestionAdder .image-wrap img {
  max-height: 60px;
  width: 100%;
}
.ProductSuggestionAdder .in-stock {
  color: #0b7630;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
}
.ProductSuggestionAdder .in-stock:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 900;
  content: "\f078";
  margin-left: 4px;
}
.ProductSuggestionAdder .product-name {
  margin-top: -4px;
  margin-bottom: 8px;
  line-height: 16px;
  max-width: 80%;
  color: #2375bb;
}
.ProductSuggestionAdder .id-numbers {
  font-size: 12px;
  color: #999;
  padding: 8px 0 0 0;
  text-align: left;
}
.ProductSuggestionAdder .spacer {
  padding: 0 10px;
}
.ProductSuggestionAdder .number-input .NumberInput {
  width: 100%;
}
.ProductSuggestionAdder .number-input .NumberInput .col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.ProductSuggestionAdder .showOnHover {
  display: none;
}
.ProductSuggestionAdder:hover .showOnHover {
  display: grid;
}
@media (max-width: 768px) {
  .ProductSuggestionAdder {
    grid-template-columns: 80px 1fr;
    grid-gap: 24px;
  }
  .ProductSuggestionAdder .showOnHover {
    display: grid;
  }
}
.ProductSuggestionAdder .col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.ProductSuggestionAdder.bullet {
  transform-origin: 0 0;
  z-index: 10;
  opacity: 1;
  border-radius: 16px;
  width: 360px;
  position: absolute;
  grid-template-columns: 80px 1fr;
}
.ProductSuggestionAdder.bullet .price {
  display: none;
}
.ProductSuggestionAdder.bullet .number-input {
  display: none;
}
@media (max-width: 768px) {
  .ProductSuggestionAdder.bullet {
    display: none;
  }
}
