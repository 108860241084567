@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.QuoteActivity {
  position: fixed;
  top: 96px;
  bottom: 8px;
  left: 32px;
  right: 32px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .QuoteActivity {
    left: 16px;
    right: 16px;
  }
}
.QuoteActivity h2 {
  left: 16px;
  right: 16px;
  font-size: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #999;
}
.QuoteActivity .Timeline {
  font-size: 14px;
  display: grid;
  grid-template-columns: 60px 1fr 100px;
  min-height: 100px;
  align-items: flex-start;
  position: relative;
  z-index: 1;
}
.QuoteActivity .left {
  display: flex;
  flex-flow: flex-start;
}
.QuoteActivity .right {
  text-align: right;
}
.QuoteActivity .Icon {
  background-color: #fff;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
}
.QuoteActivity .verticalLine {
  z-index: 0;
  position: absolute;
  top: 20px;
  left: 9px;
  width: 5px;
  height: 600px;
  background-color: #0b7630;
}
