@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.SearchResultsTop {
  margin-bottom: 16px;
  display: block;
}
@media (max-width: 768px) {
  .SearchResultsTop {
    display: none;
  }
}
.SearchResultsTop .info {
  display: grid;
  grid-template-columns: 1fr 200px 100px;
  max-width: 945px;
  align-items: center;
}
@media (max-width: 768px) {
  .SearchResultsTop .info {
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    padding: 0 8px;
  }
}
.SearchResultsTop .count {
  font-size: 16px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .SearchResultsTop .count {
    font-size: 12px;
  }
}
.SearchResultsTop .viewTypes {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.SearchResultsTop .viewTypes .Icon {
  cursor: pointer;
  padding: 0 16px;
}
.SearchResultsTop .viewTypes .Icon:last-of-type {
  border-left: 1px solid #ddd;
}
.SearchResultsTop .sortBy {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  justify-content: flex-end;
}
.SearchResultsTop .sortBy label {
  text-align: right;
  margin-right: 8px;
}
.SearchResultsTop hr {
  height: 1px;
  background-color: #ddd;
  border: none;
  margin: 16px 0;
}
.SearchResultsTop .AppliedFilters {
  display: grid;
  grid-template-columns: 68px 120px 100px 180px 60px 100px 90px 120px;
}
@media (max-width: 768px) {
  .SearchResultsTop .AppliedFilters {
    width: 100vw;
    grid-template-columns: 35% 65%;
  }
}
.SearchResultsTop .AppliedFilters h3 {
  float: left;
  display: flex;
  height: 38px;
  margin-right: 8px;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
}
.SearchResultsTop .AppliedFilters .FilterChoice {
  float: left;
  margin: 0 8px 8px 0;
  font-size: 14px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.SearchResultsTop .AppliedFilters .FilterChoice span {
  white-space: nowrap;
}
.SearchResultsTop .AppliedFilters .FilterChoice .Icon {
  display: inline-block;
  margin-left: 8px;
  padding: 4px;
  border-radius: 4px;
}
.SearchResultsTop .AppliedFilters .FilterChoice .Icon:hover {
  background-color: #ddd;
}
.SearchResultsTop .AppliedFilters .FilterChoice .Icon i {
  font-size: 12px;
}
