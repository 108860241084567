.AddToCart .Icon {
  transition: opacity 500ms;
}
.AddToCart .status-SUCCESS .Icon-check {
  opacity: 1;
}
.AddToCart .status-SUCCESS .Icon-cart {
  opacity: 0;
}
.AddToCart .status-ADDED .Icon-check {
  opacity: 0;
}
.AddToCart .status-ADDED .Icon-cart {
  transition-delay: 500ms;
  opacity: 1;
}
