@media (max-width: 768px) {
}
@media (max-width: 768px) {
}
@-moz-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-webkit-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-o-keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@keyframes wiggle {
  0%, 100% {
    opacity: 1;
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateY(-1px);
    opacity: 0.6;
  }
  75% {
    transform: translateX(1px);
  }
}
@-moz-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-webkit-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-o-keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@keyframes sweller {
  0% {
    background: $xRed;
    transform: scale(1);
  }
  50% {
    background: $cRed;
    transform: scale(2.5);
  }
  100% {
    background: $xRed;
    transform: scale(1);
  }
}
@-moz-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.ProductDetailHeader {
  padding: 0 16px 24px 16px;
  min-height: 180px;
}
.ProductDetailHeader > .inner {
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .ProductDetailHeader > .inner {
    grid-template-columns: 1fr;
  }
}
.ProductDetailHeader .actionButtons {
  width: 380px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}
.ProductDetailHeader .left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}
.ProductDetailHeader .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ProductDetailHeader .productName {
  font-size: 24px;
  margin-bottom: 8px;
}
.ProductDetailHeader .productSkus {
  font-size: 12px;
  color: #454545;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  width: 100%;
}
@media (max-width: 768px) {
  .ProductDetailHeader .productSkus {
    grid-template-columns: 1fr 1fr;
  }
  .ProductDetailHeader .productSkus span {
    display: flex;
    justify-content: flex-start;
  }
}
.ProductDetailHeader .productSkus a {
  color: #2375bb;
}
.ProductDetailHeader .productFlags .ProductFlag {
  margin: 0 8px 8px 0;
}
@media (max-width: 768px) {
  .ProductDetailHeader {
    padding-top: 100px;
    grid-template-columns: 1fr;
  }
  .ProductDetailHeader .left {
    padding-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ProductDetailHeader .left .Breadcrumbs {
    display: none;
  }
  .ProductDetailHeader .right {
    display: none;
  }
}
